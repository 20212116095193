////For Recurrence Page
.recurrence-single {
    background-color: $blue-green-color;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 15px;
    color: #FFF;
}

.recurrence-single-ended {
    background-color: $main-gray-color;
    color: #FFF;
}

.recurrence-single-non-active {
    background-color: transparentize($dangerRed, 0.2);
    color: $text_color_on_white;
}

.recurrence-single-session-list {
    margin-left: 14px;
}

.recurrence-single-session {
    border: 1px solid gray;
    padding: 5px;
    display: block;
    background-color: transparentize($positive-green-color, 0.7);
    margin-top: 5px;
}

.recurrence-single-session-linked,
.recurrence-single-session-isolated {
    background-color: $light-blue;
}

.recurrence-single-session-canceled {
    background-color: transparentize($dangerRed, 0.2);
}

.recurrence-single-session-finished {
    background-color: $main-gray-color;
}
