.round-image-container {
    height: 150px;
    width: 150px;
    min-width: 150px;
    border-radius: 50%;
    padding: 4px;
}

.round-image {
    width: 100%;
    border-radius: 50%;
    height: 100%;
    border: 1px solid $medium-gray-color;
}

.round-image-container-small {
    height: 75px;
    width: 75px;
    min-width: 75px;
}

.round-image-container-extra-small {
    height: 27px;
    width: 27px;
    min-width: 27px;
}
