.notification-badge-icon {
    font-size: 20px;
}

.notification-badge-container {
    display: inline-block;
    position: relative;
    padding: 2px 5px;
    color: $nav-bar-gray;
    cursor: pointer;

    &:hover {
        color: $main-gray-color;
    }
}

.notification-badge {
    background-color: $dangerRed;
    color: white;
    border-radius: 10px;
    padding: 1px 5px;
    font-size: 10px;
    position: absolute;
    top: -5px;
    right: -5px;
}

.notification-badge-large-number {
    right: -10px;
}

@media only screen and (max-width: 1023px) {
    .notification-badge-container {
        color: #FFF;
    }
}
