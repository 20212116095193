.member-box {
    margin-top: 15px;
}

.member-box-extras {
    width: 25%;
}

.member-box-picture-container {
    width: 25%;
}

.member-box-extras {
    border-left: 1px solid $main-gray-color;
}
