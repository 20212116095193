.profile-search-alert-single-info {
    display: flex;
}

.profile-search-alert-infos-label {
    width: 30%;
    text-align: center;
}

.profile-search-alert-infos-content {
    width: 70%;
}

.profile-search-alert-infos-container {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.profile-search-alert-actions-containers {
    width: 20%;
    border-left: 1px solid $main-gray-color;
}
