.profile-password-recovery-trigger {
    margin-left: 14px;
}

@media only screen and (max-width: 1023px) {
    .profile-password-recovery-trigger {
        margin-left: 0px;
        margin-top: 14px;
    }
}
