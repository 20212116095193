.wrapper {
    background-color: $light-gray-color;
    padding: 30px;

    h1 {
        color: $main-orange-color;
        font-weight: bold;

        &.text-color-blue-green {
            color: $blue-green-color;
        }
    }
}

.wrapper-transparent {
    background-color: transparent;
}

.wrapper-border {
    border: 1px solid $medium-gray-color;
}

.wrapper-form {
    max-width: 1024px;
    margin: auto;
    padding: 30px;
    border: 1px solid $medium-gray-color;
}

.wrapper-no-padding {
    padding: 0px;
}

.wrapper-form-no-border {
    border: none;
}

.select2-container--default .select2-results__option {
    color: $blue-green-color;

    &.select2-results__option--highlighted {
        color: #FFF;
        background-color: $blue-green-color;
    }
}

.wrapper-form {
    .select2-container {
        z-index: 1;
    }

    .select2.select2-container.select2-container--default {
        min-width: 250px;
    }

    .select2-selection__rendered {
        height: 100%;
        font-size: 14px;
    }

    .select2-search {
        height: 100%;
    }

    .select2-search__field {
        margin: 0;
        height: 100%;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        height: 35px;
        line-height: 35px;
    }

    .select2-selection--multiple,
    .select2-container--default .select2-selection--single {
        border-radius: 0;
        height: 100%;
        max-height: 35px;

        .select2-selection__arrow {
            height: 35px;
        }

        .select2-selection__rendered {
            height: 35px;
            display: flex;
            padding: 0px;
            align-items: center;
            padding-left: 5px;
        }

        .select2-selection__choice {
            max-height: 25px;
            margin: 0px;
        }

    }

    .select2-selection__clear {
        margin-right: 0;
    }

    .select2-search__field {
        font-size: 14px;
        height: 35px;
        margin: 0;
    }

    .select2-results__option {
        font-size: 14px;
    }
}

.wrapper-form-fields {
    padding: 15px;
    background-color: $extra-light-gray-color;
}

.wrapper-form-fields-no-padding {
    padding: 0px;
}

.wrapper-min-height {
    min-height: 50vh;
}

.wrapper-min-height-full {
    min-height: 100vh;
}

@media only screen and (max-width: 1023px) {
    .wrapper {
        padding: 15px;
    }

    .wrapper-no-padding {
        padding: 0px;
    }

    .wrapper-form {
        .select2.select2-container.select2-container--default {
            max-width: 100%;
            //margin-left: 10px;
        }
    }
}
