/////////////////////////////////////////////////////////////SIZES
.three-quarter-sized {
  font-size: 75%;
}
/////////////////////////////////////////////////////////////COLORS

/////////////////////////////////////////////////////////////EFFECTS
.underline-hover:hover {
  text-decoration: underline;
}

.pointer-hover:hover {
  cursor: pointer;
}
