.sport-selector-widget {
    position: inherit;
    min-width: 250px;
    margin-left: 28px;
    top: 0;
}

.sport-selector-widget-container-home {
    height: 35px;
    cursor: text;
    min-width: 250px;
}

@media only screen and (max-width: 1023px) {
    .sport-selector-widget-container-home {
        width: 100%;
        text-align: center;
    }

    .sport-selector-widget {
        min-width: 320px;
    }

    .sport-selector-widget-home {
        position: inherit;
        min-width: 250px;
    }

    .sport-selector-row-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}
