.sport-table {
    td,
    th {
        border: 1px solid $dark-gray-color;
    }

    img {
        max-width: 150px;
    }
}
