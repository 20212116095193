/****************************************************************************/
/**                            ADD CREDIT POP-UP                            */
/****************************************************************************/

.ui-datepicker-month,
.ui-datepicker-year {
	position: static;
}

.datepicker-year-month {
	.ui-datepicker-month,
	.ui-datepicker-year {
		background-color: white;
	}
}


/****************************************************************************/
/**                       Listes de choix deroulantes                       */
/****************************************************************************/
.values-list {
	top: $fixed_navBar_Height;
	max-width: 1024px;
	display: flex;
	background-color: #888;
	transition: max-height 0.2s ease-in-out;
    position: fixed;
    margin: auto;
    padding: 0px;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 200;

    svg {
    	cursor: pointer;
    	font-size: $base-font-size + 2;
    	color: white;
    	position: absolute;
    	top: 15px;
    	right: 15px;
    }

    ul {
    	display: inline-block;
    	padding-top: 15px;
    	padding-bottom: 15px;
    }

    ul:not(:first-of-type) {
    	padding-left: 15px;
    }

    ul:not(:last-of-type) {
        border-right: 1px solid #999;
    	padding-right: 15px;
    }

    ul:first-of-type {
    	padding-left: 30px;
    }

    ul:last-of-type {
    	padding-right: 30px;
    }

    li {
    	font-size: $base-font-size + 0;
    	color: #ccc;
    	margin: 12px 0;
    	cursor: pointer;
    }
}

@media screen and (max-width: 1023px) {
	.values-list {
		flex-wrap: wrap;
		overflow-y: scroll;

		p {
		    position: static;
		    width: 100%;
		    text-align: center;
		    margin-top: 15px;
		}

		ul {
			width: 100%;
		    text-align: center;
		    padding : 0 !important;
		    border-right: 0;
		    border-bottom: 1px solid #999;
		}

		ul {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 15px 0 !important;
		}

	    ul:not(:last-of-type) {
	        border-right: none;
	    }

	    li {
	    	display: inline-block;
	    	margin: 8px 0;
	    }

	    .any-sport {
	    	margin: 0 0 15px;
	    }
	}
}

/****************************************************************************/
/**                     Scrollable autocomplete results                     */
/****************************************************************************/

.ui-autocomplete {
	max-height: 150px;
	overflow-y: auto;
	overflow-x: hidden;
}
