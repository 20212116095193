.registration-panel {
    width: 30%;
    min-width: 420px;
    max-width: 520px;
    padding: 35px;
    border-radius: 20px;
    background-color: #FFF;
    -moz-box-shadow: 0 0 10px $main-gray-color;
    -webkit-box-shadow: 0 0 10px $main-gray-color;
    box-shadow: 0 0 10px $main-gray-color;
    overflow: hidden;
    height: min-content;
    min-height: 570px;
    display: flex;
    flex-direction: column;
}

.registration-panel-small {
    min-height: 350px;
}

.registration-panel-main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.registration-panel-container {
    background-image: url('../../../../../images/registration_background_desktop.png');
    background-size: 100%;
    background-position: bottom left;
    background-repeat: no-repeat;
    min-height: 100vh;
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    input[type='text'],
    input[type='email'],
    input[type='password'] {
        font-size: 16px;
    }
}

.registration-panel-list {
    max-height: 90vh;
}

.registration-panel-secondary-title {
    font-size: 16px;
}

.registration-panel-extra {
    font-size: 14px;
    margin-bottom: 10px;
}

.registration-back-link-container {
    margin-bottom: 10px;
}

.registration-logo {
    height: 30px;
}
