.google-address-status {
    color: $blue-green-color;
    font-size: 14px;
    text-align: left;
    padding: 5px 5px 14px 5px;
}

.google-address-error {
    color: $main-orange-color-very-dark;
    font-weight: bold;
}

.google-address-autocomplete {
    padding-left: 5px;
}
