.distance-selector-container {
    position: relative;

    &.distance-selector-container-search {
        margin-left: 14px;
        display: flex;
        align-items: center;
    }
}

.distance-selector-chosen-distance {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    min-width: 45px;
    border-bottom: 1px solid $blue-green-color;
    border-spacing: 10px;
    color: #fff;
    font-weight: bold;
    position: relative;

    &:hover {
        color: $blue-green-color;
    }
}

.distance-selector-chosen-distance.distance-selector-chosen-distance-search {
    color: $blue-green-color;

    &:hover {
        color: $blue-green-color;
    }
}

.distance-selector-choices-container {
    position: absolute;
    left: -60%;
    top: 120%;
    width: 150px;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -ms-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    background-color: #fff;
    border: 2px solid $blue-green-color;
    padding: 6px;
    margin-top: 2px;
    z-index: 999998;
}

.distance-selector-choice {
    display: block;
    background-color: #fff;
    text-align: center;
    color: $blue-green-color;
    font-weight: 300;
    cursor: pointer;

    &:hover {
        color: #fff;
        background-color: $blue-green-color;
    }
}

@media only screen and (max-width: 1023px) {
    .distance-selector-container {
        &.distance-selector-container-search {
            margin: 0;
            margin-top: 14px;
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            display: initial;
        }

        .distance-selector-label-search {
            display: inline;
        }

        .distance-selector-chosen-distance-search {
            height: 100%;
        }
    }

    .distance-selector-choices-container {
        right: -10%;
    }

    .distance-selector-search {
        width: 100%;
        margin-top: 14px;
    }
}
