.AS-new-form {
    color: $dark-gray-color;
    font-weight: 300;

    h1 {
        color: $main-orange-color;
        font-weight: bold;
    }

    button:not(.button-submit) {
        width: 320px;
        color: #fff;
        border: 0;
        height: 35px;
        background-color: $main-orange-color;
        cursor: pointer;
        font-weight: 400;

        &.AS-new-form-submit-green {
            background-color: $blue-green-color;
        }

        &.AS-new-form-submit-small {
            width: 200px;
        }

        &.AS-new-form-submit-gray {
            background-color: $main-gray-color;
        }
    }
}

.AS-new-form-input-container {
    background-color: $forms_sections_background_color;
    display:flex;
    align-items: center;
    height: $forms_sections_Height;
    padding-left: 15px;
    padding-right: 15px;
}

.AS-new-form-input-container-height-auto {
    height: auto;
}

.AS-new-form-input-container-with-label {
    background-color: $forms_sections_background_color;
    display:flex;
    align-items: center;
    margin-bottom: 20px;
}

.AS-new-form-input-container-with-label-vertical {
    flex-direction: column;
}

.AS-new-form-label {
    text-transform: uppercase;
    font-size: 14px;
}

.AS-new-form-label-stripe-payment {
    width: 50%;
    text-align: center;
}

.AS-new-form-input {
    height: 35px;
    border: 1px solid $medium-gray-color;
    padding-left: 7px;
    padding-right: 7px;
    margin-left: 10px;
    min-width: 250px;
    max-width: 100%;
    font-size: 14px;
    background-color: #FFF;

    &::-webkit-input-placeholder {
        text-align: center;
    }

    &:-moz-placeholder { /* Firefox 18- */
        text-align: center;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        text-align: center;
    }

    &:-ms-input-placeholder {
        text-align: center;
    }
}

.AS-new-form-input-large {
    min-width: 350px;
}

.AS-new-form-input-checkbox {
    min-width: 35px;
    height: 20px;
}

.AS-new-form-date-input {
    min-width: 95px;
    max-width: 95px;
}

.AS-new-form-date-input.AS-new-form-date-input-disable-many-days[disabled],
.AS-new-form-date-input.AS-new-form-date-input-disable-one-day[disabled] {
    background-color: $light-gray-color;
}

.AS-new-form-input-no-margin {
    margin: 0px;
}

.AS-new-form-file-input {
    height: initial;
    border: none;
    min-width: 0px;
    background-color: transparent;
}

.AS-new-form-section-title {
    font-size: 13px;
    font-weight: 400;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-left: 15px;
    border-bottom: 1px solid $light-gray-color;
    color: $dark-gray-color;
    text-transform: uppercase;
    text-align: left;
}

input[type='radio'].AS-new-form-input {
    min-width: 12px;
}

.AS-new-form-input-textarea-flash-container {
    height: 135px;
}

.AS-new-form-input-textarea.AS-new-form-input {
    height: 95px;
    width: 100%;

    .AS-new-form-input-textarea-container-with-label & {
        margin-top: 14px;
    }
}

.AS-new-form-input-full-width {
    width: 100%;
    min-width: 0px;
}

.AS-new-form-expanded-choices-container {
    display: flex;
    align-items: center;
}

.AS-new-form-input-textarea-container-with-label,
.AS-new-form-expanded-choices-container-vertical {
    flex-direction: column;
    align-items: flex-start;
}

.AS-new-form-expanded-single-choice {
    display: flex;
    align-items: center;
}

.AS-new-form-error {
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bold;
    color: $main-orange-color;
    font-size: 14px;
    text-align: left;
}

@media only screen and (max-width: 1023px) {
    .AS-new-form-input-container-with-label {
        flex-direction: column;
        margin-bottom: 36px;
    }

    .AS-new-form-label {
        margin-bottom: 10px;
        margin-top: 10px;
        display: block;
        width: 100%;
        max-width: 100%;
        text-align: center;
    }

    .AS-new-form-label-mobile-inline {
        display: inline;
    }

    .AS-new-form-input {
        margin-left: 0px;
    }

    .AS-new-form-input-radio,
    .AS-new-form-input-checkbox {
        height: auto;
    }

    .AS-new-form-input-radio {
        margin-left: 15px;
    }

    .AS-new-form-input-checkbox-with-mobile-margin-top {
        margin-top: 14px;
    }

    .AS-new-form-input-mobile-margin-top {
        margin-top: 10px;
    }

    .AS-new-form {
        button:not(.button-submit) {
            width: 100%;
        }
    }

    .AS-new-form-second-submit-button {
        margin-top: 14px;
    }

    .AS-new-form-input {
        min-width: 240px;
    }

    //TODO: Remove this and perform proper CSS
    .AS-new-form-input-stripe {
        min-width: 220px;
    }

    .AS-new-form-input-full-width {
        width: 100%;
        min-width: 0px;
    }
}
