.sport-picture-container {
    position: relative;
    width: fit-content;
    margin: auto;
    padding: 0 10px;
}

.sport-picture {
    width: 200px;
    height: 90px;
}

.sport-picture-paris-logo,
.sport-picture-organization-logo {
    position: absolute;
    bottom: 10px;
    right: 15px;
    max-width: 35px;
    max-height: 35px;
}

.sport-picture-organization-logo {
    border-radius: 100%;
}
