.stripe-payment-tooltip-container {
    text-align: right;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    font-style: italic;
}

.stripe-payment-tooltip-container .stripe-payment-tooltip-text {
    visibility: hidden;
    width: 400px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    margin-left: -220px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 1s;
    cursor: default;
}

.stripe-payment-tooltip-container .stripe-payment-tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.stripe-payment-tooltip-container:hover .stripe-payment-tooltip-text {
    visibility: visible;
    opacity: 1;
}

.stripe-payment-security-info {
    background: #6ED641;
    padding: 5px 15px;
    margin: 20px 5px 0 5px;
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.stripe-payment-container {
    display: flex;
}

.stripe-payment-form-container {
    width: 50%;
    border-right: 1px solid $dark-gray-color;
}

.stripe-payment-extra-infos-container {
    width: 50%;
}

.stripe-payment-methods-label {
    color: $dark-gray-color;
    margin-bottom: 14px;
    font-size: 16px;
}

.stripe-payment-form-submit {
    text-align: center;
    margin-top: 26px;
}

.stripe-payment-form-errors {
    text-align: center;
    color: $main-orange-color-dark;
    font-weight: bold;
    margin-bottom: 14px;
}

@media screen and (max-width : 1023px) {
    .stripe-payment-container {
        flex-direction: column;
    }

    .stripe-payment-form-container {
        width: 100%;
        border-bottom: 1px solid $dark-gray-color;
        border-right: none;
    }

    .stripe-payment-extra-infos-container {
        width: 100%;
    }
}

@media screen and (max-width : 480px) {
    .stripe-payment-tooltip-container .stripe-payment-tooltip-text {
        width: 300px;
    }
}
