////displays////

.display-inline-block {
    display: inline-block;
}

.display-flex {
    display: flex;
}

.display-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hidden {
    display: none;
}

.full-width {
    width: 100%;
}

.eighty-percent-width {
    width: 80%;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

////////////////////

////text sizes////

.text-extra-small {
    font-size: 11px;
}

.text-small {
    font-size: 12px;
}

.text-regular {
    font-size: 14px;
}

.text-medium {
    font-size: 18px;
}

.text-large {
    font-size: 24px;
}

//.block-left p.text-regular {
//    font-size: 14px;
//}

.text-normal {
    font-size: 16px;
}

h2.text-normal {
    font-size: 16px;
}

////////////////////

////text styles////

.text-italic {
    font-style: italic;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-underlined {
    text-decoration: underline;
}

.text-bold {
    font-weight: bold;
}

.text-normal-weight {
    font-weight: 400;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-initial {
    text-transform: initial;
}

.text-crossed {
    text-decoration: line-through;
}

////text colors////

.text-white {
    color: #FFF;
}

.text-default {
    color: $medium-gray-color;
}

.text-gray {
    color: $main-gray-color;
}

.text-dark-gray {
    color: $text_color_on_white
}

//.block-left p.text-dark-gray {
//    color: $text_color_on_white;
//}

.text-main-orange {
    color: $main-orange-color;
}

.text-link-bittersweet-orange {
    color: $main-orange-color;
    cursor: pointer;

    &:hover {
        color: $main-orange-color-dark;
        text-decoration: underline;
    }
}

.text-color-blue-green {
    color: $blue-green-color;
}

.text-link-blue-green {
    color: $blue-green-color;
    cursor: pointer;

    &:hover {
        color: $blue-green-color-dark;
        text-decoration: underline;
    }
}

.text-link-gray {
    color: $main-gray-color;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        color: $dark-gray-color;
        text-decoration: none;
    }
}

.text-link-underlined {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.text-positive {
    color: #6ED641;
}

////////////////////

////background colors////
.text-background-light-gray {
    background-color: $light-gray-color;

    .AS-elements &.AS-element {
        background-color: $light-gray-color;
    }
}

.text-background-white {
    background: #FFF;
}

.text-background-none {
    background: none;
}

////hover////

.hover-underline:hover {
    text-decoration: underline;
}

body a.hover-underline:hover {
    color: $main-gray-color;
}

////////////////////

////border////
.border-solid-gray {
    border: 1px solid $medium-gray-color;
}

.border-bottom-solid-gray {
    border-bottom: 1px solid $medium-gray-color;
}

.border-solid-blue {
    border: 1px solid $blue-green-color;
}

////////////////////

.vertical-align-middle {
    vertical-align: middle;
}

//lists
main {
    .list-unstyled {
        li {
            list-style: none;
        }

        li.list-unstyled-element {
            list-style: none;
        }
    }
}

////text styles////
.text-warning {
    color: $main-orange-color;
    font-style: italic;
}

////lists////
.list-styled-disc {
    list-style: disc;
    list-style: inside;
    padding: inherit;
}

////text-opacity////
.text-opacity-70 {
    opacity: 0.7;
}

.text-opacity-50 {
    opacity: 0.5;
}

.text-opacity-0 {
    opacity: 0;
}

////titles////
.center-gray-bold-title {
    font-weight: 700;
    text-align: center;
    color: $main-gray-color
}

////buttons////
.btn-green {
    background-color: $blue-green-color;
    color: #fff;
}

////lines////
hr.gradient {
    border: 0;
    height: 1px;
    margin: 5px 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

////cursor////
.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

////height////
.max-h-150 {
    max-height: 150px;
}
