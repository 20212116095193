.company-choice-box {
    padding: 10px;
    display: flex;
    background-color: #fff;
    border: 1px solid $main-gray-color;
    border-radius: 10px;
    height: 90px;
    cursor: pointer;
    margin-bottom: 5px;
    color: $dark-gray-color;

    //In order to overwrite default user agent black color of buttons in our "company-choice-box-form" elements
    .company-choice-box-infos {
        color: $dark-gray-color;
    }

    &:hover,
    &:focus,
    &:active {
        border-width: 5px;
        border-color: $main-orange-color;

        .company-choice-box-infos-name {
            color: $main-orange-color-dark;
            text-decoration: underline;
        }

        .company-choice-box-infos-address {
            text-decoration: none;
        }
    }
}

.company-choice-box-form {
    cursor: initial;
}

.company-choice-box-picture-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.company-choice-box-picture {
    height: 100%;
    max-height: 100%;
    width: 100%;
    object-fit: contain;
}

.company-choice-box-infos {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: capitalize;
    justify-content: center;
    text-align: center;
}

.company-choice-box-search-container {
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.company-choice-box-search-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}

.company-choice-box-search-icon {
    position: absolute;
    top: calc(50% - 0.5em);
    color: $main-gray-color;
}

.company-choice {
    ._mCS_1 .mCSB_container {
        margin-right: 15px;
    }
}

.company-choice-box-infos-container {
    width: 100%;

    &:focus {
        outline: none;
        border: none;
    }
}

.company-choice-empty-list-message {
    text-align: center;
}

.company-choice-box-infos-address {
    font-size: 12px;
    color: $medium-gray-color;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 5px;
    max-width: 100%;
    text-transform: capitalize;
}
