a.button-link,
.button-submit {
    height: 35px;
    border: none;
    color: #FFF;
    background-color: $main-orange-color;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 150px;

    &:hover {
        background-color: $main-orange-color-dark;
    }
}

a.button-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

a.button-submit-large,
.button-submit-large {
    width: $button-large-width-desktop;
    height: 60px;
    font-size: 25px;
    max-width: 100%;
}

a.button-submit-medium,
.button-submit-medium {
    width: 200px;
    height: 40px;
    font-size: 18px;
}

a.button-submit-medium-plus,
.button-submit-medium-plus {
    width: 250px;
    height: 45px;
    font-size: 16px;
    font-weight: bold;
}

a.button-max-width,
.button-max-width {
    max-width: 150px;
}

a.button-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

a.button-submit-default,
.button-submit-default {
    background-color: $medium-gray-color;

    &:hover {
        background-color: $medium-gray-color-dark;
    }
}

.button-submit-disabled {
    background-color: $medium-gray-color;
    cursor: not-allowed;
    opacity: 0.7;

    &:hover {
        background-color: $medium-gray-color;
    }
}

a.button-submit-green,
.button-submit-green {
    background-color: $blue-green-color;

    &:hover {
        background-color: $blue-green-color-dark;
    }
}

a.button-submit-positive-green,
.button-submit-positive-green {
    background-color: $positive-green-color;

    &:hover {
        background-color: $positive-green-color-dark;
    }
}

a.button-submit-danger,
.button-submit-danger {
    background-color: $dangerRed;

    &:hover {
        background-color: $dangerRed-dark;
    }
}

a.button-link-full-width,
.button-submit-full-width {
    width: 100%;
}

a.button-facebook {
    background-color: $facebook-blue;

    &:hover {
        background-color: $facebook-blue-dark;
    }
}

a.button-google {
    background-color: $google-red;

    &:hover {
        background-color: $google-red-dark;
    }
}

a.button-google-white {
    background-color: #FFF;
    border: 1px solid $main-gray-color;
    color: $main-gray-color;

    &:hover {
        background-color: darken(#FFF, 20%);
    }
}

.button-transparent {
    background-color: transparent;
}

.button-submit-small {
    width: 200px;
}

.button-submit-small-profile-subscription {
    width: 150px;
}

a.button-submit-extra-small,
.button-submit-extra-small {
    width: 125px;
    min-width: 125px;
}

a.button-submit-square,
.button-submit-square {
    min-width: 35px;
    width: 35px;
    height: 35px;
    padding: 0px;
}

.button-container-flash {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
}

.button-container-flash-credit {
    align-items: initial;
}

.button-flash-second-button {
    margin-left: 14px;
}

.button-container {
    display: flex;
}

@media only screen and (max-width: 1023px) {
    .button-flash-second-button {
        margin-top: 14px;
        margin-left: 0px;
    }

    a.button-submit-large,
    .button-submit-large {
        width: $button-large-width-mobile;
        font-size: 18px;
        height: 50px;
    }
}
