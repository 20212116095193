.search-map {
    min-height: 800px;
    width: 100%;
}

.search-map-info-window {
    padding-left: 1%;
    min-width: 250px;
    position: relative;
    text-align: center;
}

.search-map-info-window-link {
    display: block;
    width: 100%;
    z-index: 1;

    &.search-map-info-window-link-not-allowed {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.search-map-info-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 210px;
}

.search-map-info-image {
    width: 200px;
}

//TODO: Improve max-width rules later for media queries
@media only screen and (max-width: 480px) {
    .search-map-info-image-container {
        min-height: 160px;
    }

    .search-map-info-image {
        width: 155px;
    }
}

.search-map-info-section {
    margin-top: 5px;
    color: #777d80;
    font-weight: 400;
}

.search-map-info-icon {
    margin-right: 10px;
}

.search-map-info-name {
    margin-top: 14px;
    text-transform: capitalize;
    color: $main-orange-color;
    font-weight: 700;
}

.search-map-reserved-mention {
    width: 200px;
    background-color: $main-orange-color;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    bottom: 50%;
    left: 50%;
    margin-left: -100px;
    z-index: 2;
    font-weight: 400;
}
