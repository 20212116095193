.profile-search-alert-container {
    box-shadow: 0px 0px 1px $main-orange-color;
    display: flex;
    margin: 15px 0;
    background-color: $forms_sections_background_color;
    min-height: 155px;
    color: $text_color_on_white;
}

.profile-search-alert-info-title {
    font-weight: bold;
    margin-bottom: 0;
}

.profile-search-alert-infos-container,
.profile-search-alert-actions-containers {
    padding: 15px;
    box-sizing: border-box;
}

.profile-search-alert-actions-containers {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-search-alert-new-alert-container {
    text-align: center;
    margin-top: 59px;
}

@media screen and (max-width: 1023px) {
    .profile-search-alert-container {
        flex-direction: column;
    }
}
