.session-invitation-type-button {
    border: 2px solid $blue-green-color;
    background-color: white;
    padding: 12px;
    text-align: center;
    margin: 6px;
    min-width: 155px;
    color: $blue-green-color;

    &:hover {
        cursor: pointer;
        background-color: $blue-green-color;
        color: white;
    }

    &.active {
        background-color: $blue-green-color;
        color: #fff;
    }
}

.session-invitation-filter-container {
    display: flex;
    align-items: center;
    margin-top: 14px;
}

.session-invitation-checkbox-container input {
    display: none;
}

.session-invitation-display-checkbox {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background: white;
    border: 2px solid $blue-green-color;
    position: relative;

    &.session-invitation-display-checkbox-checked:after {
        opacity: 1;
    }

    &:after {
        opacity: 0;
        content: '';
        position: absolute;
        width: 9px;
        height: 5px;
        background: transparent;
        top: 6px;
        left: 5px;
        border: 3px solid $blue-green-color;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
    }

    &:hover:after{
        opacity: 0.5;
    }
}

.session-invitation-send-button {
    width: 30%;
    background-color: $blue-green-color;
    color: #fff;
    padding: 10px;
    cursor: pointer;
}

.session-invitation-wrapper {
    color: $text_color_on_white;
    min-height: 200px;
}

.session-invitation-more-members-container {
    margin-top: 36px;
    text-align: center;
}

.session-invitation-more-members-button {
    background-color: $main-orange-color;
    padding: 10px;
    cursor: pointer;
    color: #fff;
    width: 30%;
}

.session-invitation-textarea {
    background-color: rgb(248, 248, 248);
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #DDD;
    height: 150px;
    padding: 15px;

    &::placeholder {
        color: $main-gray-color;
        font-style: italic;
        font-size: 14px;
    }
}

.session-invitation-author {
    color: $main-orange-color;
}

.session-invitation-email {
    padding: 30px;
}

.session-invitation-checkbox-all-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 26px;
    margin-bottom: 26px;
}

.session-invitation-no-found-members {
    color: $main-orange-color;
    font-weight: bold;
    margin-top: 54px;
    padding-bottom: 26px;
    text-align: center;
}

.session-invitation-category-content {
    margin-top: 54px;
}

.session-invitation-notification {
    text-align: center;
    color: $blue-green-color;
    font-weight: bold;
    display: block;
    margin: 16px 0;
}

.session-invitation-vertical-line {
    margin-right: 10px;
    margin-left: 10px;

    &:after {
        content: "\007C"
    }
}

.session-invitation-not-found-members-explanation {
    margin-top: 14px;
    font-size: 13px;
    font-style: italic;
    color: $text_color_on_white;
}

@media only screen and (max-width: 1023px){
    .session-invitation-filter-container {
        font-size: 14px;
    }

    .session-invitation-send-button,
    .session-invitation-more-members-button {
        width: 100%;
    }
}
