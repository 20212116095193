/****************************************************************************/
/**                        ALL FRONT-END VARIABLES                         **/
/****************************************************************************/

//////COLORS
$background-section-color: #ebebeb;
$forms_sections_background_color: rgb(249,249,249);
$important-color: #00B8E6;

//RED-ORANGE
$main-orange-color: #ff7963;
$main-orange-color-dark: darken($main-orange-color, 5%);
$main-orange-color-very-dark: darken($main-orange-color, 20%);
$google-red: #D24031;
$google-red-dark: darken($google-red, 5%);
$dangerRed: #ff566f;
$dangerRed-dark: darken($dangerRed, 5%);

//GREEN
$positive-green-color: #00c97f;
$positive-green-color-dark: darken($positive-green-color, 5%);
$light-green: #D5F1D8;

//BLUE
$blue-green-color: #137984;
$blue-green-color-dark: darken($blue-green-color, 5%);
$facebook-blue: #3f5c9a;
$facebook-blue-dark: darken($facebook-blue, 5%);
$light-blue: #008ECC;

//GRAY
$main-gray-color: #999;
$medium-gray-color: #cecece;
$medium-gray-color-dark: darken($medium-gray-color, 5%);
$medium-gray-color-dark-plus: darken($medium-gray-color, 10%);
$nav-bar-gray: #666;
$light-gray-color: #ebebeb;
$extra-light-gray-color: #f8f8f8;
$dark-gray-color: #777;
$text_color_on_white: #55585a;

//WHITE
$white: #FFF;

//////DIMENSIONS
$fixed_navBar_Height: 80px;
$forms_sections_Height: 60px;
$forms-padding: 30px;
$button-large-width-desktop: 450px;
$button-large-width-mobile: 450px;

//////REFERENCE FONT-SIZE
$base-font-size: 14px;
