.sprite-payment-icon {
    display: inline-block;
}

.sprite-payment-icons-visa,
.sprite-payment-icons-mastercard,
.sprite-payment-icons-american-express,
.sprite-payment-icons-cb {
    background-image: url('../../../../../images/payment_icons.png');
    background-repeat: no-repeat;
}

.sprite-payment-icons-visa {
    background-position: 0 0;
    width: 45px;
    height: 28px;
}

.sprite-payment-icons-mastercard {
    background-position: -45px 0;
    width: 45px;
    height: 28px;
}

.sprite-payment-icons-american-express {
    background-position: 0 -28px;
    width: 45px;
    height: 28px;
}

.sprite-payment-icons-cb {
    background-position: -45px -28px;
    width: 41px;
    height: 28px;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.0), screen and (min--moz-device-pixel-ratio: 1.0), screen and (-o-min-device-pixel-ratio: 100/100), screen and (min-device-pixel-ratio: 1.0), screen and (min-resolution: 1.0dppx) {
    .sprite-payment-icons-visa,
    .sprite-payment-icons-mastercard,
    .sprite-payment-icons-american-express,
    .sprite-payment-icons-cb {
        background-image: url('../../../../../images/payment_icons.png');
        -webkit-background-size: 90px 56px;
        -moz-background-size: 90px 56px;
        background-size: 90px 56px;
    }
}
