.subscription {
    font-size: 22px;
    color: $text_color_on_white;
}

.subscription-name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subscription-name {
    margin-top: 14px;
    font-weight: bold;
    font-size: 25px;
    color: $blue-green-color;
    text-transform: uppercase;
    text-align: center;
}

.subscription-available-sessions-container {
    padding: 30px;
}

.subscription-infos-transparent {
    opacity: 0.5;
}

.subscription-period-checklist {
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subscription-warning {
    text-align: center;
    font-weight: bold;
    margin-top: 59px;
    color: $main-orange-color;
    padding: 26px;
    border: 1px solid $main-orange-color;
    font-size: 18px;
}

.subscription-yearly-precision {
    margin-top: 26px;
    font-size: 16px;
    font-style: italic;
}

.subscription-availability-info {
    text-align: center;
    font-size: 16px;
    font-style: italic;
    color: $main-orange-color;
}

.subscription-session-list-title {
    font-weight: bold;
    text-align: center;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 26px;
    color: $blue-green-color;
    width: 100%;
}
