//Input-gray

.input-gray-hover,
.select2-selection--multiple.input-gray-hover-select2-container {
    border: 1px solid $light-gray-color;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    padding: 3px;
    background-color: $extra-light-gray-color;

    &:hover {
        background-color: $light-gray-color;
    }

    &:focus,
    &:active {
        background-color: transparent;
        outline: none;
        border: none;
        box-shadow: 0 0 0 1pt $main-orange-color-dark;
    }
}

.select2-container--open .select2-selection--multiple.input-gray-hover-select2-container,
.select2-container--focus .select2-selection--multiple.input-gray-hover-select2-container {
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: 0 0 0 1pt $main-orange-color-dark;

    &:hover {
        background-color: transparent;
    }
}

.select2-container--default .input-gray-hover-select2-dropdown .select2-results__option {
    color: $dark-gray-color;

    &.select2-results__option--highlighted {
        background-color: $main-orange-color;
        color: #FFF;
    }
}
