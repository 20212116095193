.company-choice-box-form-container {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.company-choice-box-infos-container {
    display: flex;
}

.company-choice-box:hover {
    .company-choice-box-form-container {
        display: flex;
    }

    .company-choice-box-infos-container {
        display: none;
    }
}

.company-choice-box-search-icon {
    left: 10px;
}

.company-choice-box-picture-container {
    width: 25%;
}

.company-choice-box-infos {
    width: 75%;
    padding-left: 10px;
    padding-right: 10px;
}

//Important to have height for the mCustomScrollbar to function
.company-choice-list {
    height: 50vh;
}

.company-choice {
    font-size: 18px;

    .company-choice-box-search[type='text'] {
        font-size: 18px;
    }

    .company-choice-box-search[type='text']::placeholder {
        font-size: 18px;
    }
}
