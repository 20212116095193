.announcement {
    margin-bottom: 26px;
    border: 1px solid gray;
    padding: 10px;
}

.announcement-picture {
    max-height: 200px;
    width: auto;
    max-width: 100%;
}
