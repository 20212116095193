.google-address-autocomplete {
    min-width: 380px;
}

.google-address-autocomplete-search {
    min-width: 280px;
}

@media only screen and (max-width: 1023px) {
    .google-address-autocomplete {
        min-width: 240px;
        width: 100%;
        margin-top: 14px;
    }
}
