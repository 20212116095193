.notification {
    background-color: $light-green;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.notification-title {
    text-transform: uppercase;
    font-size: 18px;
    color: $text_color_on_white;
}

.notification-unread .notification-title {
    color: $blue-green-color;
}

.notification-date {
    font-size: 12px;
    color: $blue-green-color;
}

.notification-unread {
    background-color: #F6EAE4;
}
