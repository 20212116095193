table {
    width: 100%;
}

table  tbody tr:nth-child(odd) {
    background-color: #ccc;
}

table tr td {
    padding: 3px;
}
