.search-submit-container {
	display: flex;
	justify-content: flex-end;
}

.search-alert-button {
	margin-left: 14px;
}

.single-search-result {
	width: 100%;
}

.search-announcement-container {
	display: block;
	margin-top: 50px;
	margin-bottom: 50px;
}

.search-announcement {
	margin: 15px auto 0;
	width: 100%;
	max-width: 700px;
	height: 155px;
	display: block;
}

.search-infos-location {
	margin-left: 2px;
	margin-bottom: 4px;
}

@media (max-width: 480px) {
	.search-infos-location-see-map {
		display: none;
	}
}

.As-element-infos-session-state-standard {
	color: #777d80;
}

@media all and (max-width: 1023px) {
	.search-submit-container {
		flex-direction: column;
	}

	.search-alert-button {
		margin-left: 0;
	}

	.button-container-flash {
		flex-direction: column;
	}

	.pub {
		width: 90vw;
		height: 20vw;
	}
}

//TODO: remove after you have found a suitable place for the map
.search-map-button-container {
	padding-top: 26px;
	margin-top: 26px;
	border-top : 1px solid rgba(0,0,0,0.1);
	color: $text_color_on_white;
	font-weight: 300;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}

@media only screen and (max-width: 1023px) {
	.search-coach-action {
		width: 100%;
	}

	//TODO: remove after you have found a suitable place for the map
	.search-map-button-container {
		flex-direction: column;
	}

	.search-map-button-action-container {
		width: 100%;
		margin-top: 14px;
	}

	.search-map-button-action {
		width: 250px;
		height: 45px;
		font-size: 16px;
		font-weight: bold;
		margin: auto;
	}
}
