.profile-infos {
	background-color: #FFF;
	padding: 15px;
	margin-bottom: 15px;
	color: $text_color_on_white;
	text-align: center;

	h1 {
		color: $main-orange-color;
		font-weight: 700;
		padding-bottom: 10px;
		border-bottom: 1px solid $light-gray-color;
	}

	th {
		background-color: #FFF;
		color: $text_color_on_white;
		text-align: center;
	}
}

.profile-progress {
	background-color: #ebebeb;
	height: 100%;

	span {
		padding-left: 10px;
		font-weight: bold;
	}
}

.profile-progress-bar-bonus {
	text-align: right;
	font-style: italic;
}

.profile-infos-wrapper-image {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.profile-progress-bar-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	margin-top: 40px;
	position: relative;
}

.profile-progress-bonus-caret-container {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	text-align: center;
}

.profile-progress-caret-down {
	width: 10%;
}

.profile-progress-bar {
	border: 1px solid $text_color_on_white;
	width: 80%;
}

.profile-progress-bar-lower-limit {
	width: 10%;
}

.profile-progress-bar-upper-limit {
	width: 10%;
}

@media only screen and (max-width: 767px) {
	.profile-infos-wrapper {
		flex-direction: column;
		justify-content: center;
		align-items: center;

		ul {
			margin-left: 0;
			text-align: center;
		}
	}

	.profile-infos-wrapper-image {
		width: 100%;
	}

	.profile-infos-wrapper-stats {
		margin-top: 14px;
		margin-left: 0px;
	}
}
