.manager-credit-wrapper {
    min-height: 700px;
}

.manager-credit-links-wrapper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 59px;
    margin-top: 26px;
}

.manager-credit-links-wrapper-team-manager {
    justify-content: center;
}

.manager-credit-link-container {
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 60px;
    text-decoration: none;
    border: 2px solid $blue-green-color;
    color: $main-orange-color;

    &:hover {
        cursor: pointer;
    }

    &:hover,
    &.manager-credit-link-container-active {
        color: #FFF;
        background-color: $blue-green-color;
    }
}

.manager-credit-link-container-team-manager {
    margin: 7px;
}

.manager-credit-member-balance {
    margin-left: 14px;
    color: $blue-green-color;
    font-weight: bold;
}
