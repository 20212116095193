.AS-elements {
	.AS-element,
	.AS-element-infos,
	.AS-element-action {
		min-height: 155px;
		padding-bottom: 5px;
	}

	@media (max-width: 767px) {
		.AS-element-action {
			min-height: 75px;
		}
	}

	.AS-element img.square-64 {
        height: 64px;
        width: 64px;
    }

	.AS-element {
		width: 100%;
		position: relative;
		display:flex;
		flex-direction: row;
		align-items: center;
	    margin-top: 15px;
		background-color: white;
		box-shadow: 1px 1px 2px rgba(0, 153, 0, 0.2);

		img {
			order: 1;
		}

		.AS-element-action {
			order: 3;
			position:absolute;
			right: 0;
			width: 155px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			border-left: 1px solid #eee;
			top: 0;

			p {
				font-size: 14px;
				font-weight: 700;
			}

			.AS-btn {
				width: 125px;
				font-size: 16px;
				margin: 10px auto;
			}
		}

		.AS-element-infos {
			order: 2;
			position: relative;
			font-size: 14px;
			font-weight: 400;
			color: #777d80;
			margin-left: 20px;
		    margin-right: 170px;
		    width: 100%;

			.AS-element-title {
				font-weight: 700;
				color: #777d80 !important;
				font-size: 14px;
				text-transform: uppercase;
				margin: 15px 0 5px 0 !important;
				padding: 0 !important;
				max-width: 75%;
			}

			p {
				line-height: 25px;				
			}

		}
	}

	.AS-element {
		.AS-element-action p:nth-child(2) {
			margin-top: 5px;
			font-size: 13px;
			line-height: 17px;
			font-weight: 400;
		}
	}

	.AS-element.past-not-rated .AS-element-action p:nth-child(2) {
		color: $main-orange-color;
	}
}

.AS-element-main-infos {
	float: left;
}

.AS-element-infos-session-state-premium {
	color: $main-orange-color;
}

.AS-element-extra-infos-session {
	float: right;
	text-align: right;
	margin-top: 10%;

	&.AS-element-extra-infos-session-premium {
		margin-top: 8.5%;
	}
}

@media all and (max-width: 1023px) {
	.AS-element-extra-infos-session {
		font-size: 12px;
	}
}

@media only screen and (max-width: 767px) {
	.AS-elements {
		text-align: center;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0;

		.AS-element {
 			height: auto;
			margin: 10px auto;
    		max-width: 310px;
    		display: inline-block; 

    		.AS-element-infos {
	 			height: auto;
	 			min-height: 200px;
    			margin-left: 0;
			    padding-right: 5px;
			    padding-left: 5px;
			    box-sizing: border-box;
    		}		
		}

		.AS-element .AS-element-action {
			box-sizing: border-box;
			position: relative;
			float: right;
			border-top: 1px solid #eee;
			border-left: none;
			width: 100%;
			padding: 0;
		}
	}

	.AS-element-main-infos {
		width: 100%;
	}

	.AS-element-extra-infos-session {
		float: none;
		text-align: center;
		margin-top: 0;

		&.AS-element-extra-infos-session-premium {
			margin-top: 0;
		}
	}
}


@media only screen and (max-width: 320px) {
	.AS-elements {
		.AS-element {
			.AS-element-action {
				.AS-btn {
					width: auto;
				}
			}
		}
	}
}
