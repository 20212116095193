.registration-social-media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.registration-social-media-link:first-of-type {
    margin-bottom: 5px;
}

.registration-social-media-logo-container {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.registration-social-media-label {
    width: 90%;
    text-align: center;
}

.registration-social-media-standard-separator-container {
    margin-bottom: 30px;
    margin-top: 30px;
    color: $dark-gray-color;
    text-align: center;
    text-transform: lowercase;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;

    &:before {
        border-top: 2px solid $light-gray-color;
        content: '';
        margin: 0 auto; /* this centers the line to the full width specified */
        position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
        top: 50%; left: 0; right: 0; bottom: 0;
        width: 95%;
        z-index: -1;
    }
}

.registration-social-media-standard-separator {
    padding: 5px;
    border: 1px solid $light-gray-color;
    border-radius: 50%;
    background-color: #FFF;
    font-size: 16px;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.registration-oauth-actions-container {
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
}

.register-oauth-link-no-account {
    margin-left: 14px;
}

.register-oauth-link {
    max-width: 220px;
}

.registration-company-picture-container {
    margin-top: 15px;
    margin-bottom: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $main-orange-color;
}

.registration-company-picture-wrapper {
    height: 130px;
    max-width: 200px;
    margin-bottom: 24px;
}

.registration-company-picture {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.registration-panel-main-title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    color: $main-orange-color;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
}

.registration-panel-main-title-address {
    font-size: 12px;
    color: $medium-gray-color;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 5px;
    max-width: 100%;
    text-transform: capitalize;
}

.registration-panel-secondary-title {
    text-align: center;
    color: $text_color_on_white;
    text-transform: uppercase;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1023px) {
    .registration-oauth-actions-container {
        flex-direction: column;
    }

    .register-oauth-link-no-account {
        margin-left: 0px;
        margin-top: 14px;
    }

    .register-oauth-link {
        max-width: 100%;
        width: 100%;
    }
}

.registration-panel-list {
    overflow: hidden;
}

.registration-panel-container {
    color: $text_color_on_white;

    .registration-input-with-icon,
    .registration-input-with-icon,
    .registration-input-with-icon {
        padding-left: 30px;
    }

    button[type='submit']:not(.button-transparent),
    a.button-link:not(.button-transparent) {
        border-radius: 5px;
        width: 100%;
        height: 40px;
        letter-spacing: 1px;
    }
}

.registration-radio-container {
    display: flex;
    align-items: center;

    input[type="radio"] {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    label {
        position: relative;
        margin-right: 1em;
        padding-left: 2em;
        padding-right: 1em;
        line-height: 2;
        cursor: pointer;

        &:before {
            box-sizing: border-box;
            content: " ";
            position: absolute;
            top: 0.3em;
            left: 0;
            display: block;
            width: 1.4em;
            height: 1.4em;
            border: 1px solid $main-gray-color;
            border-radius: .25em;
        }
    }

    input[type="radio"] + label::before {
        border-radius: 1em;
    }

    input[type="radio"]:checked + label {
        padding-left: 1em;
        color: $main-orange-color;

        &:before {
            top: 0;
            width: 100%;
            height: 2em;
            border-color: $main-orange-color;
        }
    }

    label,
    label::before {
        -webkit-transition: .25s all ease;
        -o-transition: .25s all ease;
        transition: .25s all ease;
    }
}

.registration-input-container {
    margin-bottom: 5px;
    padding: 5px;
    text-align: center;
}

.registration-input-container-checkbox {
    display: flex;
    align-items: center;

    input[type='checkbox'] {
        background: $extra-light-gray-color;
        border: 1px solid $light-gray-color;
        flex-shrink: 0;
        height: 20px;
        margin-right: 10px;
        width: 20px;
    }
}

.registration-password-conditions {
    font-style: italic;
    font-size: 10px;
    padding: 5px;
}

.registration-password-conditions-errors {
    font-weight: bold;
    color: $main-orange-color-very-dark;
    font-size: 12px;
}

.registration-error {
    padding: 5px;
    color: $main-orange-color-very-dark;
    font-weight: bold;
    margin-bottom: 5px;
}

.registration-form-plain-password-container .registration-error {
    display: none;
}

.registration-form-plain-password-container .registration-error-displayed-forced .registration-error {
    display: block;
}

.registration-exit-link {
    font-size: 13px;
}

.registration-field-usage-info {
    font-size: 10px;
    margin-bottom: 14px;
    color: $medium-gray-color-dark-plus;
    font-style: italic;
    padding: 5px;
}

.registration-minor-field-label {
    font-size: 10px;
    color: $medium-gray-color-dark-plus;
}

.registration-logo-container {
    margin-bottom: 30px;
    text-align: center;
}
