.member-box {
    display: flex;
    background-color: #fff;
    box-shadow: 1px 1px 2px $main-gray-color;
}

.member-box-infos {
    font-size: 14px;
    width: 50%;
    padding-bottom: 10px;
    padding-top: 10px;
    color: $main-gray-color;
}

.member-box-extras {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.member-box-picture-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.member-box-company-picture-wrapper {
    height: 75px;
    max-width: 100px;
}

.member-box-company-picture {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
