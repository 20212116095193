.admin-subscription-single {
    background-color: $blue-green-color;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 15px;
    color: #FFF;
}

.admin-subscription-single-canceled {
    background-color: transparentize($dangerRed, 0.2);
    color: $text_color_on_white;
}

.admin-subscription-single-unpublished {
    background-color: $light-blue;
}

.admin-subscription-single-session-list {
    margin-left: 14px;
}

.admin-subscription-single-session {
    border: 1px solid gray;
    padding: 5px 10px;
    display: block;
    background-color: transparentize($positive-green-color, 0.7);
    margin-top: 5px;
}

.admin-subscription-single-session-ended {
    background-color: $main-gray-color;
}

.admin-subscription-single-session-canceled {
    background-color: transparentize($dangerRed, 0.2);
}

.admin-subscription-single-session-excluded {
    background-color: $main-gray-color;
}

.admin-subscription-single-session-name-container {
    display: flex;
}

.admin-subscription-single-session-name {
    width: 80%
}

.admin-subscription-single-session-actions {
    width: 20%;
    text-align: right;
}
