.page-popup-background {
    min-width: 100vw;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    z-index: 999998;
    left: 0;
    top: 0;
}

.page-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
}

.page-popup-container {
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    position: fixed;
    z-index: 999999;
    justify-content: center;
    align-items: center;
    margin: auto;
    left: 0;
    right: 0;
    top: 30vh;
    height: auto;
    padding: 30px;
    box-sizing: border-box;
    color: $text_color_on_white;
    background-color: #fff;
    width: 600px;
    max-width: 90%;

    //TODO: Remove this once you have proper CSS for page popups
    h2:not(.form-title) {
        color: $text_color_on_white;
    }
}

.page-popup-container-high {
    top: 20vh;
}

.page-popup-container-very-high {
    top: 10vh;
    max-height: 80vh;
    overflow: auto;
}

.page-popup-container-short {
    max-height: 350px;
}

.page-popup-container-small {
    width: 350px;
}

.page-popup-title {
    font-weight: 400 !important;
    margin-top: 0 !important;
    padding: 10px 0;

    main .wrapper & {
        font-weight: 400 !important;
        margin-top: 0 !important;
    }
}

.page-popup-link {
    color: $blue-green-color;
    text-decoration: underline;
}
