.spacer-outer-auto {
    margin: auto;
}

.spacer-outer-bottom-none {
    margin-bottom: 0px;
}

.spacer-outer-bottom-tiny {
    margin-bottom: 5px;
}

main h1.spacer-outer-bottom-tiny {
    margin-bottom: 5px;
}

.spacer-outer-bottom-small {
    margin-bottom: 14px;
}

.spacer-outer-left-none {
    margin-left: 0px;
}

.spacer-outer-left-tiny {
    margin-left: 5px;
}

.spacer-outer-left-small {
    margin-left: 14px;
}

.spacer-outer-left {
    margin-left: 26px;
}

.spacer-outer-bottom-none {
    margin-top: 0px;
}

main h2.spacer-outer-bottom-none {
    margin-top: 0px;
}

.spacer-outer-top-tiny {
    margin-top: 5px;
}

.spacer-outer-top-small {
    margin-top: 14px;
}

.spacer-outer-top-medium {
    margin-top: 20px;
}

.spacer-outer-top {
    margin-top: 26px;
}

.spacer-outer-top-large {
    margin-top: 59px;
}

.spacer-outer-right-none {
    margin-right: 0px;
}

.spacer-outer-right-tiny {
    margin-right: 5px;
}

.spacer-outer-right-small {
    margin-right: 14px;
}

.spacer-outer-right-tiny {
    margin-right: 5px;
}

.spacer-outer-right-small {
    margin-right: 14px;
}

.spacer-outer-bottom {
    margin-bottom: 26px;
}

.spacer-outer-bottom-large {
    margin-bottom: 59px;
}

.spacer-inner-tiny {
    padding: 5px;
}

.spacer-inner-small {
    padding: 14px;
}

.spacer-inner {
    padding: 26px;
}

.spacer-inner-top {
    padding-top: 26px;
}

.spacer-inner-top-small {
    padding-top: 14px;
}

.spacer-inner-top-small {
    padding-top: 14px;
}

.spacer-inner-bottom-small {
    padding-bottom: 14px;
}

.spacer-inner-tiny {
    padding: 5px;
}

.spacer-inner-right-tiny {
    padding-right: 5px;
}

.spacer-inner-right-small {
    padding-right: 14px;
}

.spacer-inner-left-tiny {
    padding-left: 5px;
}

.spacer-inner-left-small {
    padding-left: 14px;
}
