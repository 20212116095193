/****************************************************************************/
/**                           GENERAL LAYOUT                               **/
/****************************************************************************/

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

ul {
	list-style: none;
}

body {
	font-family: 'Open Sans', sans-serif;

	main {
		background-size: cover;
		background-attachment: fixed;
	}

	//////PAGES SECTIONS OFFSET, BECAUSE OF FIXED NAVBAR
	> section,
	> main,
	> footer,
	> aside {
		position: relative;
		top: $fixed_navBar_Height;

		//TODO: remove this when you have set a proper CSS
		&.footer-top-zero {
			top: 0px;
		}
	}

	//////ALLSESSIONS BUTTONS-STYLE
	.AS-btn,
	a.AS-btn {
		-webkit-appearance: none;
		font-family : inherit;
		background-color: $medium-gray-color;
		height: 35px;
		border: none;
		padding: 0 15px;
		color: white;
		font-size: 15px;
		font-weight: 400;
		cursor: pointer;
	}

	a.AS-btn {
		display: inline-block;
		line-height: 35px;
	}

	.AS-btn.btn-green {
		background-color: $blue-green-color !important;
	}

	.AS-btn.btn-search {
		background-color: $main-orange-color !important;
	}

	.AS-btn.btn-create {
		background-color: $main-orange-color !important;
	}

	.AS-btn.btn-default {
		background-color: $medium-gray-color !important;

		&:hover {
			background-color: $medium-gray-color-dark-plus !important;
		}
	}

	.AS-btn.btn-important {
		background-color: $important-color !important;
	}

	.clr-search {
		color: $blue-green-color !important;
	}

	.clr-create {
		color: $main-orange-color !important;
	}

	.clr-default {
		color: $medium-gray-color !important;
	}

	a.link {
		text-decoration: underline;
		color: $main-orange-color;
		font-weight: 400;
		cursor: pointer !important;
	}

	//////MAX-WIDTH TO 1024px AND CENTER BLOCK
	.wrapper {
		position: relative;
		width: 1024px;
		box-sizing: border-box;
		margin: auto;
		max-width: 100%;
	}

	h1 {
		font-weight: 400;
		font-size: 17px;
		text-align: center;
	    text-transform: uppercase;
	}

	h2,
	h3 {
		text-transform: uppercase;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	p {
		line-height: 25px;
	}

	p.center {
		text-align: center;
	}

	&.no-header {
		> section,
		> main,
		> footer,
		> aside {
			top: 0;
		}
	}
}

@media only screen and (max-width: 1023px) {
	.wrapper {
		width: 100%;
	}
}

@media only screen and (max-width: 767px) {
	body {
		> section,
		> main,
		> footer,
		> aside {
			top: $fixed_navBar_Height * 2;
		}

		.wrapper {
			width: 100%;
		}
	}
}

h1 {
	margin-bottom: 40px;
}

h2:not(.form-title) {
	font-size: 3px + $base-font-size;
	color: $blue-green-color;
}

h2.text-main-orange {
	color: $main-orange-color;
}

/////////////////////////////////////////////////////////////HEADER AND FOOTER
header {
	display:flex;
	flex-direction: row;
	align-items: center;	
	height: $fixed_navBar_Height;
	background-color: $background-section-color;
    width: 100%;
    position: fixed;
    z-index: 99999;
    top: 0;
    padding: 0px 30px;
    color: $nav-bar-gray;
    box-shadow: 0px 1px 2px #bbb;

    #logo {
    	width: 148px;
    	height: auto;
		margin-right: 18px;
    }

    #main-buttons {
    	display:flex;
		flex-direction: row;
		align-items: center;	
		height: $fixed_navBar_Height;

		a {
			width: 148px;
			text-align: center;
		}

		p,
		a:first-of-type {
			margin-right: 14px;
		}
    }

    nav.logged {
		width: 600px;
    }

	.avatar-li {
		position: relative;
		width: 50%;
		border-right: 1px solid $nav-bar-gray;
		margin-right: 25px;
	}

    nav.not-logged {
		width: 430px;
    }

	nav {
		position: absolute;
		right: 0px;
		top: 0;
		font-size: 14px;
		display:flex;
		flex-direction: row;
		align-items: center;	
		height: $fixed_navBar_Height;

		.inscription {
			font-weight: 700;
			color: $main-orange-color;
		}

		#Open-Menu,
		#Close-Menu {
			cursor: pointer;
			display: none;

			svg,
			p {
				transition: all 0.2s ease-in-out;
			}

			p {
				text-transform: uppercase;
				display: inline;
    			line-height: 32px;
    			margin-left: 5px;
			}
		}

		#Open-Menu {
			svg {
				fill: $nav-bar-gray;
			}			
		}

		#Close-Menu {
			svg {
				fill: #aaa;
			}

			p {
				color: #aaa;
			}
		}

		#Open-Menu:hover,
		#Open-Menu:active {
			svg {
				fill: #999;
			}

			p {
				color: #999;
			}			
		}

		#Close-Menu:hover,
		#Close-Menu:active {
			svg {
				fill: #fff;
			}

			p {
				color: #fff;
			}			
		}


		li {
			position: relative;
			padding-left: 5px;
			padding-right: 5px;
		}

		li a {
			display: block;
		}

		#avatar,
		#member_name {
			display: inline;
		}

		#member_name:after {
			content: '\25bc';
			margin-left: 3px;
		}

		@media only screen and (max-width: 1023px){
			#member_name {
				display: none;
			}
		}

		.avatar-image {
			float: left;
			border-radius: 50%;
			width: 48px;
			height: 48px;
		}

		.avatar-team-image {
			position: absolute;
			background-color: #fcfcfc;
			top: 50%;
			left: 8%;
			border-radius: 50%;
			width: 36px;
			height: 36px;
		}

		.avatar-info {
			font-weight: bold;
			padding-top: 4.5%;
			padding-left: 20%;
		}
		/******************* first level ******************/

		> ul {
			width: 100%;
			display: flex;
	    	flex-direction: row;
	    	align-items: center;
			margin-right: 15px;

			> li {
				flex-grow: 1;
			}

			> li > a {
				color: $nav-bar-gray;
			}
		}

		#connection-button {
			color: $nav-bar-gray;
			border: none;
			display: inline-block;	
			background: none;	
			font-size: 14px;
			line-height: 14px;
			cursor: pointer;
			margin-right: 0;	
			font-family: 'Open Sans', sans-serif;
		}

		.team-button {
			text-decoration: underline;
			border-right: 1px solid $nav-bar-gray;
			margin-right: 30px;
			padding-right: 30px;
			text-align: right;
		}

		/******************* second level ******************/

		li ul {
			color: #999;
			background-color: $background-section-color;
			display: none;
			position: absolute;
			top: 100%;
			box-shadow: 1px 1px 2px #bbb;
			min-width: 150px;
		}

		li:hover ul	{
			display: block;
			left: 0;
			right: 0;
		}

		li:not( :first-child ):hover ul	{
			left: -1px;
		}

		li ul li a:hover,
		li ul:not( :hover ) li.active a	{
			background-color: #fcfcfc;
		}

		li ul li a {
			color: #999;
			font-size: 14px;
			line-height: 25px;
			padding-left: 25px;
		}
	}
}

@media only screen and (max-width: 1180px) {
	header nav.logged {
		width: 470px;
	}
}

footer {
	width: 100%;
	background-color: #414141;
	font-weight: 400;
	padding: 20px 0 0;

	#footer-wrapper {
		display: flex;
		padding-bottom: 40px;
    	border-bottom: 1px solid $nav-bar-gray;

		li a {
			line-height: 25px;
			color: #ccc;

			&:hover {
				opacity: 0.5;
			}
		}
		
		#footer-actions p {
			color: #ccc;
		}

		#sitemap {
			width: 75%;
			margin-right: 5%;
			flex-direction: row;
			display:flex;
			justify-content: space-between;
		}

		#footer-actions {
			width: 80%;
			display:flex;
			justify-content: space-between;
			flex-direction: column;
			padding: 0;
			margin: 0;

			p {
				margin-right: 20px;	
				display: inline;			
			}

			input[type="email"] {
				position: static;
				width: 240px;
				font-size: 15px;
			}

			#social-media {
				* {
					display: inline;	
				}

				ul {
					position: relative;
					top: 18px;
				}
			}

			.AS-btn {
				display: inline;
			}
		}
	}

	#legal-mentions p {
		font-size: 12px;
		text-align: center;
		color: $nav-bar-gray;
	}
}

@media only screen and (max-width: 1023px) {
	header {
		nav.logged,
		nav.not-logged {
			width: auto;			
		}

		a:last-of-type button {
			margin-right: 0;
		}

		nav {
			width: auto;

			#Open-Menu {
				margin-right: 15px;
				margin-top: -5px;
			}

			#Open-Menu,
			#Close-Menu {
				display: flex;

				a {
					display: flex;	
				}

			}

			#member_name::after {
				content: '';
			}


			/* first level */

			> ul {
				z-index: 1000;
				flex-direction: column;
				height: 100%;
				top: 0;
				position: fixed;
				width: auto;
				right: -100%;
				justify-content: flex-start;
				background-color: rgba(0,0,0,0.9);
				padding: 30px;
				transition: right 0.3s ease-in-out;
				margin-right: 0;

				.team-button,
				.avatar-li {
					border: none;
					margin-right: 0;
					padding-right: 0;
					text-align: center;

					.avatar-image {
						float: none;
					}

					.avatar-team-image {
						top: 20%;
						left: 50%;
					}

					.avatar-info {
						padding: 0;
						margin-top: 10px;
					}
				}
			}

			> ul > li {
				flex-grow: 0;
				margin-bottom: 25px;
				text-align: center;
			}


			> ul > li > a,
			#connection-button {
				color: white;
			}

			/* second level */

			li ul {
				display: block;
				position: static;
				background-color: transparent;
				box-shadow: none;
			}

			li ul li > a {
				color: $main-orange-color;
				text-indent: 0;
				text-align: center;
				line-height: 35px;
				padding-left: 0;
			}

			> ul > li > a,
			li ul li > a,
			#connection-button  {
				font-size: 18px;
			}

			li ul li a:hover,
			li ul:not( :hover ) li.active a	{
				background-color: transparent;
				opacity: 0.8;
			}
		}
	}

	footer {
		#footer-wrapper {
			flex-direction: column;
			align-items: center;

			#sitemap {
				width: 60%;
				margin-right: 0;
				padding-bottom: 40px;
			    margin-bottom: 40px;
			    border-bottom: 1px solid $nav-bar-gray;
			}

			#footer-actions {
				width: auto;
			}

			#footer-actions form {
				margin-bottom: 20px;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	header {
		#main-buttons {
			padding: 0 15px;
			position: fixed;
		    top: $fixed_navBar_Height;
		    width: 100%;
		    box-sizing: border-box;
		    background-color: $background-section-color;
		    margin: auto;
		    left: 0;
		    right: 0;
		    justify-content: center;
		    border-top: 1px solid #dadada;
    		border-bottom: 1px solid #dadada;

		    p {
		    	display: none;
		    }

		    .AS-btn {
		    	line-height: 32px;
		    }
		}
	}

	footer {
		#footer-wrapper {
			#sitemap {
				flex-direction: column;
				align-items: center;

				ul {
					width: 100%;
					margin-bottom: 20px;
				}
			}

			#footer-actions {
				p {
					display: block;
					text-align: center;
					margin-bottom: 20px;
				}

				form {

					input[type="email"],
					input[type="submit"] {
						width: 100%;
					}
				}		
			}
		}
	}
}
