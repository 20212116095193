.custom-checkbox-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    input {
        display: none;
    }
}

.custom-display-checkbox {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background: white;
    border: 2px solid $blue-green-color;
    position: relative;
    margin-right: 14px;

    &.custom-display-checkbox-checked:after {
        opacity: 1;
    }

    &:after {
        opacity: 0;
        content: '';
        position: absolute;
        width: 9px;
        height: 5px;
        background: transparent;
        top: 6px;
        left: 5px;
        border: 3px solid $blue-green-color;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
    }
}

.custom-checkbox-container-disabled {
    opacity: 0.5;

    .custom-display-checkbox {
        cursor: no-drop;

        &[data-status="subscribed"]:after {
            opacity: 1;
        }

        &[data-status="subscribed"]:hover:after {
            opacity: 1;
        }

        &[data-status="complete"]:after {
            opacity: 0;
        }

        &[data-status="complete"]:hover:after {
            opacity: 0;
        }
    }
}

.custom-checkbox-precision {
    font-style: italic;
    margin-bottom: 5px;
    font-size: 18px;
}

.custom-checkbox-stripe-wrapper .custom-checkbox-precision {
    text-align: center;
}

.custom-checkbox-labelled-input {
    display: flex;
    width: 100%;
}
