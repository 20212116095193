.news-feed {
    background-color: $forms_sections_background_color;
    padding: 15px;
    position: relative;
    word-wrap: break-word;
    margin-top: 26px;
}

.news-feed-actions-container {
    position: absolute;
    right: 15px;
    top: 15px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    display: flex;
}

.news-feed:hover,
.news-feed:active,
.news-feed:focus {
    .news-feed-actions-container {
        opacity: 1;
    }
}
